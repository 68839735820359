* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0;
}

.main {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
}

.nav {
  width: 200px;
  height: 2000px;
  display: flex;
  justify-content: center;
  align-content: center;

  background-color: #0081A7;

  .image {
    font-size: 2rem;
    color: #FDFCDC;
    padding-top: 120px;
    text-align: center;

    p {
      padding: 1rem;
    }

    img {
      width: 140px;
    }

    .email {
      margin-top: 2rem;

      input {
        height: 46px;
        padding: 1rem;
        border: none;
        border-radius: 10px;
        line-height: normal;
        background-color: #FDFCDC;
        color: #282828;
        outline: none;
        box-shadow: 0px 2px 0px 0px #FED9B7;
      }
    }
  }
}

.table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  .content {
    width: 1100px;
    margin-top: 40px;
    justify-content: center;
    align-content: center;

    thead {
      font-size: 1rem;
      background-color: #FED9B7;
      color: #F07167;

      th {
        padding: 0.8rem;
      }
    }

    tbody {
      font-size: 1rem;
      text-align: start;

      td {
        padding: 0.8rem;
      }

      tr {
        border: none;
        line-height: normal;
        background-color: transparent;
        color: #282828;
        outline: none;
        box-shadow: 0px 2px 0px 0px #FED9B7;
      }
    }
  }
}